.file-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 13px !important;
  margin-top: 1rem;
}

.file-info-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-height: 165px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 13px !important;
}

.file-info-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 0 !important;
  border-radius: 13px;
  margin-bottom: 15px;
  margin-right: 20px;
  max-height: 40px;
}

.file-info-details {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: 50px;
  font-weight: 600 !important;
  color: white !important;
  border-radius: 13px 0 0 13px !important;
  background-color: #03b2ff;
}

.file-info-url {
  text-decoration: none !important;
}

.file-link-font {
  font-size: 12px !important;
}

.file-info-name {
  max-width: 125px;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.file-error-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 65px;
  overflow-y: auto;
  overflow-x: hidden;
}

.file-error-message {
  max-width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pdf {
  background: #d62828;
}

.docx {
  background: #274c77;
}

.xlsx {
  background: #00ac47;
}

.csv {
  background: #008000;
}

.doc {
  background: #0077b6;
}

.txt {
  background: #e9c46a;
}
